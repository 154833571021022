import { HTTP } from '../../../http-common'
import helper from '../../services'

export default {
  namespaced: true,
  state: {
    adminElections: [],
    userElections: [],
    selectedElection: {}
  },
  getters: {},
  mutations: {
    SET_ADMIN_ELECTIONS: (state, elections) => state.adminElections = elections,
    SET_USER_ELECTIONS: (state, elections) => state.userElections = elections,
    SET_SELECTED_ELECTION: (state, data) => state.selectedElection = data,
    CLEAR_SELECTED_ELECTION: (state) => state.selectedElection = {}
  },
  actions: {
    async getAdminElections({ commit }) {
      try {
        const res = await HTTP.get(
          'business/overview/elections',
          { headers: helper.setHeaders() }
        )
        commit('SET_ADMIN_ELECTIONS', res.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getUserElections({ commit, rootGetters }) {
      try {
        const res = await HTTP.get(
          `members/${ rootGetters.user.profile_id.member_id }/elections`,
          { headers: helper.setHeaders() }
        )
        commit('SET_USER_ELECTIONS', res.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateElection(context, data) {
      try {
        await HTTP.put(
          'elections/' + data.id,
          data,
          {
            headers: helper.setHeaders()
          }
        )
        if (data.id === context.state.selectedElection.id)
          context.commit('SET_SELECTED_ELECTION', { id: data.id, ...data })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async clearElection(context) {
      try {
        context.commit('CLEAR_SELECTED_ELECTION')
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}
import axios from 'axios'
import router from './src/router'
import store from './src/store'
import helper from './src/services'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${ store.getters.token }`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
HTTP.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 410) {
      router.replace('/error410')
    }
    helper.errorHandler(err.response)
    return Promise.reject(err)
  }
)

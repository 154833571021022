<template>
  <v-snackbar
    v-model='globalLoaderData.isOpen'
    :color='globalLoaderData.color'
    :timeout='-1'
    bottom
    class='white--text'
    elevation='24'
    right
  >
    {{ globalLoaderData.text }}
    <v-progress-linear
      :value='globalLoaderData.progress'
      class='mt-3 primary--text'
      color='white'
      height='15'
      rounded
    >
      <template #default={value}>
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </v-snackbar>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'GlobalLoader',
  computed: {
    ...mapState(['globalLoaderData'])
  },
  created() {
    window.addEventListener('beforeunload', () => {
      this.SET_GLOBAL_LOADER_DATA({
        isOpen: false,
        color: '',
        text: '',
        progress: 0
      })
    })
  },
  methods: {
    ...mapMutations(['SET_GLOBAL_LOADER_DATA'])
  }
}
</script>

<style scoped>

</style>
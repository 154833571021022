<template>
  <v-navigation-drawer :mini-variant='!drawer' app color='primary' permanent width='250'>
    <v-list-item class='justify-center my-8'>
      <v-list-item-avatar :size='drawer ? 100 : 40' class='ma-0 transition'>
        <v-img alt='logo' src='../assets/images/mcbalogo2.jpg'></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-list dense>
      <template v-for='item in operations'>
        <v-list-group
          v-if="'child' in item && item.child.length"
          :key='item.id'
          class='mb-4 customGroup'
        >
          <template #activator>
            <v-list-item-icon class='mr-4'>
              <v-icon class='white--text'>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class='white--text'>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for='child in item.child'
            :key='child.id'
            :to='child.path'
            active-class='customActiveClass'
            class='my-2'
          >
            <v-list-item-icon class='pl-4 mr-4'>
              <v-icon class='white--text'>{{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class='white--text'>
                {{ child.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key='item.id'
          :to='item.path'
          class='mb-4'
          @click='logout(item)'
        >
          <v-list-item-icon class='mr-4'>
            <v-icon class='white--text'>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class='white--text'>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <div style='display: none'>{{ token.token }}</div>
  </v-navigation-drawer>
</template>

<script>
import home from '../services/home'
import profile from '../services/userOperations'
import {mapState} from 'vuex'

export default {
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  async mounted() {
    await this.initialize()
  },
  computed: {
    ...mapState(['impersonalSidebar', 'impersonal']),
    operations() {
      if (this.impersonal) return this.impersonalSidebar
      return this.$store.getters.operations
    },
    token() {
      return this.$store.getters.token
    }
  },
  methods: {
    async initialize() {
      try {
        const profileOperations = await profile.getOperations()
        await this.$store.dispatch('operations', profileOperations)
      } catch (err) {
        console.log(err)
      }
    },
    async logout(value) {
      if (value.name === 'Logout') {
        try {
          await home.logout()
          setTimeout(() => {
            this.$store.dispatch('impersonal', false)
            this.$store.dispatch('electionStore/clearElection')
            this.$store.dispatch('userCopy', {
              user: {
                name: '',
                rol: '',
                image: ''
              },
              token: ''
            })
            this.$store.dispatch('token', '')
            this.$store.dispatch('user', {
              name: '',
              rol: '',
              image: ''
            })
            this.$store.state.employeeStore.isShowedVoterRegistrationPopup = false
            this.$store.state.employeeStore.showedVoterRegistrationPopupAt = null
            this.$router.replace({ name: 'Login' })
          }, 0)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.customGroup {
  .v-list-item {
    padding-right: 0 !important;
  }
  .v-list-group__header__append-icon {
    justify-content: start !important;
    min-width: 0 !important;
  }
  .mdi-chevron-down {
    color: white !important;
    margin-left: -50px;
  }
}
.customActiveClass {
  color: rgba(0, 0, 0, 0.87) !important;
}
.transition {
  transition: .3s;
}

</style>

import { HTTP } from '../../../http-common'
import helper from '../../services'

const communicationStore = {
  namespaced: true,
  state: {
    templates: [],
    employees: [],
    counties: [],
    templateItem: {},
    employeeRoles: [],
    communicationsData: {}
  },
  mutations: {
    SET_COUNTIES: (state, data) => state.counties = data,
    SET_TEMPLATES: (state, data) => state.templates = data,
    SET_EMPLOYEES: (state, data) => state.employees = data,
    SET_TEMPLATE_ITEM: (state, data) => state.templateItem = data,
    SET_EMPLOYEE_ROLES: (state, data) => state.employeeRoles = data,
    SET_COMMUNICATIONS_DATA: (state, data) => state.communicationsData = data
  },
  actions: {
    async getCounties({ commit }) {
      try {
        const res = await HTTP.get('get-counties', {
          headers: helper.setHeaders()
        })
        commit('SET_COUNTIES', res.data.data)
        return res.data.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getTemplates({ commit }) {
      try {
        const response = await HTTP.get(
          'communication-template-select',
          { headers: helper.setHeaders() })
        commit('SET_TEMPLATES', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployees({ commit }, data) {
      try {
        const response = await HTTP.get(
          'employees/get/select',
          {
            params: data,
            headers: helper.setHeaders()
          })
        commit('SET_EMPLOYEES', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async showTemplate({ commit }, id) {
      try {
        const response = await HTTP.get(
          `communication-template/${ id }`,
          { headers: helper.setHeaders() }
        )
        commit('SET_TEMPLATE_ITEM', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployeeRoles({ commit }) {
      try {
        const response = await HTTP.get(
          'employees/get/roles',
          { headers: helper.setHeaders() }
        )
        commit('SET_EMPLOYEE_ROLES', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async saveCommunication(context, data) {
      try {
        await HTTP.post(
          'communication', data,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateCommunication(id, data) {
      try {
        await HTTP.put(
          `communication/${ id }`, data,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async saveTemplate(data) {
      try {
        await HTTP.post(
          'communication-template', data,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateTemplate(id, data) {
      try {
        await HTTP.put(
          `communication-template/${ id }`, data,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getCommunications({ commit }, filters) {
      try {
        const response = await HTTP.get(
          'communication',
          {
            params: filters,
            headers: helper.setHeaders()
          })
        commit('SET_COMMUNICATIONS_DATA', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async deleteCommunication(context, communicationId) {
      try {
        await HTTP.delete(
          `communication/${ communicationId }`,
          { headers: helper.setHeaders() }
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}
export default communicationStore

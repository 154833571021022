import store from '../store'
import router from '../router'

export default {
  setHeaders: () => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getters.token}`
    }
  },
  async errorHandler(response) {
    let color = 'grey'
    let message = response.statusText
    switch (response.status) {
      case 200:
        color = 'success'
        message = response.data.message ? response.data.message : response.data
        break
      case 404:
        color = 'warning'
        message = response.data.message ? response.data.message : message
        break
      case 422:
        color = 'info'
        message = response.data.message ? response.data.message : message
        break
      
      case 500:
        color = 'error'
        message = response.data.message ? response.data.message : message
        break
      
      default:
        color = 'grey'
        message = response.data.message ? response.data.message : message
        break
    }
    await store.dispatch('snackbar', {
      show: true,
      color: color,
      message: message
    })
    
    if (response.status === 410) {
      await router.replace({name: 'Error410'})
    }
    if (response.status === 401) {
      setTimeout(async() => {
        await store.dispatch('impersonal', false)
        await store.dispatch('userCopy', {
          user: {
            name: '',
            rol: '',
            image: ''
          },
          token: ''
        })
        await store.dispatch('user', '')
        await store.dispatch('token', '')
        await router.replace({name: 'Login'})
      }, 1000)
    }
  }
}

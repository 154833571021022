import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from '../services/user'
import memberService from '../services/member'
import employeeStore from './modules/employeeStore.js'
import adminStore from './modules/adminStore'
import charityStore from './modules/charityStore'
import donationStore from './modules/donationStore'
import memberStore from './modules/memberStore'
import communicationStore from './modules/communicationStore'
import userStore from './modules/userStore'
import votersGuideStore from './modules/votersGuideStore'
import campaignStore from './modules/campaignStore'
import cmsStore from './modules/cmsStore'
import reportingStore from './modules/reportingStore'
import electionStore from './modules/electionStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    impersonalSidebar: [],
    modules: [],
    token: '',
    crud: {
      dialog: false,
      title: '',
      id: null,
      data: {}
    },
    crudTwo: {
      dialog: false,
      title: '',
      id: null,
      data: {}
    },
    alert: {
      show: false,
      color: 'grey',
      title: '',
      message: '',
      timeout: 4000,
      response: false
    },
    user: {
      name: '',
      rol: '',
      image: '',
      profile_id: ''
    },
    operations: {
      name: [],
      description: [],
      path: [],
      icon: []
    },
    charities: [],
    charityEventDetail: {},
    charityBankAccount: { status_account: 0 },
    charity: {},
    commission: null,
    snackbar: {
      show: false,
      color: 'grey',
      message: '',
      timeout: 4000
    },
    opportunity: {},
    employee: {},
    employeeId: Number,
    election: {},
    eventBus: false,
    impersonal: false,
    userCopy: {
      user: {
        name: '',
        rol: '',
        image: ''
      },
      token: ''
    },
    image: '',
    voted_url: '',
    reportingFilters: {},
    isRoutePushed: false,
    loadingDistrictDownload: false,
    globalLoaderData: {
      isOpen: false,
      color: '',
      text: '',
      progress: 0
    },
    voterGuideParams: {}
  },
  mutations: {
    SET_IMPERSONAL_SIDEBAR: (state, data) => state.impersonalSidebar = data,
    SET_GLOBAL_LOADER_DATA: (state, data) => state.globalLoaderData = data,
    SET_EMPLOYEE_ID: (state, id) => state.employeeId = id,
    setDistrictLoading(state, step) {
      return (state.loadingDistrictDownload = step)
    },
    setModules(state, data) {
      return state.modules = [...data]
    },
    token(state, step) {
      return (state.token = step)
    },
    crud(state, step) {
      return (state.crud = step)
    },
    crudTwo(state, step) {
      return (state.crudTwo = step)
    },
    alert(state, step) {
      return (state.alert = step)
    },
    user(state, step) {
      return (state.user = step)
    },
    operations(state, step) {
      return (state.operations = step)
    },
    charities(state, step) {
      return (state.charities = step)
    },
    charityEventDetail(state, step) {
      return (state.charityEventDetail = step)
    },
    charityBankAccount(state, step) {
      return (state.charityBankAccount = step)
    },
    charity(state, step) {
      return (state.charity = step)
    },
    commission(state, step) {
      return (state.commission = step)
    },
    snackbar(state, step) {
      return (state.snackbar = step)
    },
    opportunity(state, step) {
      return (state.opportunity = step)
    },
    employee(state, step) {
      return (state.employee = step)
    },
    eventBus(state, step) {
      return (state.eventBus = step)
    },
    impersonal(state, step) {
      return (state.impersonal = step)
    },
    userCopy(state, step) {
      return (state.userCopy = step)
    },
    image(state, step) {
      return (state.image = step)
    },
    election(state, step) {
      return (state.election = step)
    },
    voted_url(state, step) {
      return (state.voted_url = step)
    },
    reportingFilters(state, step) {
      return (state.reportingFilters = step)
    },
    changeRoutePush(state, payload) {
      state.isRoutePushed = payload
    },
    saveVoterGuideUserParams(state, data) {
      state.voterGuideParams = data
    }
  },
  actions: {
    async addModules({ commit, getters }, member_id = null) {
      const memberId = member_id || getters.user.profile_id.member_id
      try {
        const modules = await memberService.modules(memberId)
        commit('setModules', modules)
      } catch (err) {
        console.log(err.response)
      }
    },
    token(context, data) {
      context.commit('token', data)
    },
    setDistrictLoading({ commit }, data) {
      commit('setDistrictLoading', data)
    },
    crud(context, data) {
      context.commit('crud', data)
    },
    crudTwo(context, data) {
      context.commit('crudTwo', data)
    },
    alert(context, data) {
      context.commit('alert', data)
    },
    user(context, data) {
      context.commit('user', data)
    },
    operations(context, data) {
      context.commit('operations', data)
    },
    charities(context, data) {
      context.commit('charities', data)
    },
    charityEventDetail(context, data) {
      context.commit('charityEventDetail', data)
    },
    charityBankAccount(context, data) {
      context.commit('charityBankAccount', data)
    },
    charity(context, data) {
      context.commit('charity', data)
    },
    commission(context, data) {
      context.commit('commission', data)
    },
    snackbar(context, data) {
      context.commit('snackbar', data)
    },
    opportunity(context, date) {
      context.commit('opportunity', date)
    },
    employee(context, date) {
      context.commit('employee', date)
    },
    eventBus(context, data) {
      context.commit('eventBus', data)
      setTimeout(() => {
        context.commit('eventBus', false)
      }, 1000)
    },
    impersonal(context, date) {
      context.commit('impersonal', date)
    },
    userCopy(context, date) {
      context.commit('userCopy', date)
    },
    election(context, data) {
      context.commit('election', data)
    },
    async image(context) {
      try {
        const res = await user.getImage()
        context.commit('image', res)
      } catch (e) {
        // empty
      }
    },
    voted_url(context, data) {
      context.commit('voted_url', data)
    },
    reportingFilters(context, data) {
      context.commit('reportingFilters', data)
    }
  },
  getters: {
    currentUserID: (state) => state.user.id,
    userRoleId: (state) => state.user.id_rol,
    getEmployeeId: ({ employeeId }) => employeeId,
    getDistrictLoading({ loadingDistrictDownload }) {
      return loadingDistrictDownload
    },
    getModules: state => state.modules,
    token(state) {
      return state.token
    },
    crud(state) {
      return state.crud
    },
    crudTwo(state) {
      return state.crudTwo
    },
    alert(state) {
      return state.alert
    },
    user(state) {
      return state.user
    },
    operations(state) {
      return state.operations
    },
    charities(state) {
      return state.charities
    },
    charityEventDetail(state) {
      return state.charityEventDetail
    },
    charityBankAccount(state) {
      return state.charityBankAccount
    },
    charity(state) {
      return state.charity
    },
    commission(state) {
      return state.commission
    },
    snackbar(state) {
      return state.snackbar
    },
    opportunity(state) {
      return state.opportunity
    },
    employee(state) {
      return state.employee
    },
    eventBus(state) {
      return state.eventBus
    },
    impersonal(state) {
      return state.impersonal
    },
    userCopy(state) {
      return state.userCopy
    },
    image(state) {
      return state.image
    },
    election(state) {
      return state.election
    },
    employee_id(state) {
      return state.user.profile_id.employee_id
    },
    voted_url(state) {
      return state.voted_url
    },
    reportingFilters(state) {
      return state.reportingFilters.reportingFilters // return value, not observer
    },
    getPushRoute(state) {
      return state.isRoutePushed
    },
    voterGuideUserParams(state) {
      return state.voterGuideParams
    }
  },
  modules: {
    adminStore,
    employeeStore,
    charityStore,
    donationStore,
    memberStore,
    communicationStore,
    userStore,
    votersGuideStore,
    campaignStore,
    cmsStore,
    reportingStore,
    electionStore
  },
  plugins: [
    createPersistedState({
      key: 'mcba'
    })
  ]
})

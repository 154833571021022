import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  getProfile: async () => {
    try {
      const response = await HTTP.get('getProfile', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  getEntity: async (id, communicationType) => {
    try {
      const response = await HTTP.get(`getEntity/${ id }/${ communicationType }`, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  getActiveMembers: async (communicationType) => {
    try {
      const response = await HTTP.get(`getActiveMembers/${ communicationType }`, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  index: async (params) => {
    try {
      const response = await HTTP.get('users', {
        params,
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  store: async ({
                  first_name,
                  last_name,
                  user_name,
                  email,
                  user_profile_id,
                  entity
                }) => {
    try {
      const response = await HTTP.post(
        'users',
        {
          first_name,
          last_name,
          user_name,
          email,
          user_profile_id,
          entity
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      return e.response
    }
  },
  show: async (id) => {
    try {
      const response = await HTTP.get(`users/${ id }`, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  updated: async (
    id,
    { first_name, last_name, user_name, email, user_profile_id, entity }
  ) => {
    try {
      const response = await HTTP.put(
        `users/${ id }`,
        {
          first_name,
          last_name,
          user_name,
          email,
          user_profile_id,
          entity
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  async deleted(id) {
    try {
      const response = await HTTP.delete(`users/${ id }`, {
        headers: helper.setHeaders()
      })
      return response.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  async deletedItems(items) {
    const response = await HTTP.post(`users/deleteitems`, {
      items
    })
    return response.data
  },
  impersonate: async (id) => {
    try {
      const response = await HTTP.get(`impersonate/${ id }`, {
        headers: helper.setHeaders()
      })
      return response
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  },
  getImage: async () => {
    try {
      const response = await HTTP.get(`getImage`, {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      await helper.errorHandler(e.response)
      return e.response
    }
  }
}

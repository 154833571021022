import { HTTP } from '../../../http-common'
import helper from '../../services'
import employeeStore from './employeeStore'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    donationStatistics: {},
    subordinates: [],
    votingStatistics: {},
    volunteeringStatistics: {},
    memberHoursData: {}
  },
  getters: {
    allocation: state => state.donationStatistics.allocation
  },
  mutations: {
    SET_DONATION_STATISTICS: (state, data) => state.donationStatistics = data,
    SET_SUBORDINATES: (state, data) => state.subordinates = data,
    REFRESH_SUBORDINATES: (state) => {
      state.subordinates.forEach(item => {
        item.isOpened = false
        item.selectedTreeview = []
      })
    },
    SET_VOTING_STATISTICS: (state, data) => state.votingStatistics = data,
    SET_VOLUNTEERING_STATISTICS: (state, data) => state.volunteeringStatistics = data,
    SET_MEMBER_HOURS_DATA: (state, data) => state.memberHoursData = data
  },
  actions: {
    async getMemberHours({ rootState, commit }) {
      try {
        const res = await HTTP.get(`member-year/${ rootState.user.profile_id.member_id }`, {
          headers: helper.setHeaders()
        })
        commit('SET_MEMBER_HOURS_DATA', res.data.data)
      } catch (e) {
        await store.dispatch('snackbar', {
          show: true,
          color: 'error',
          timeout: 8000,
          message: 'The hours available to contribute for each employee are not configured for the current year. Please verify and complete below in "Set Limit Hours".'
        })
      }
    },
    async createMemberHours({ commit }, data) {
      try {
        const res = await HTTP.post('member-year/create', data, {
          headers: helper.setHeaders()
        })
        commit('SET_MEMBER_HOURS_DATA', res.data.data)
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateMemberHours({ commit }, data) {
      try {
        const res = await HTTP.post('member-year/update', data, {
          headers: helper.setHeaders()
        })
        commit('SET_MEMBER_HOURS_DATA', res.data.data)
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateEmployees(context, file) {
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      try {
        await HTTP.post('employees-update-import', file, { headers })
        store.commit('snackbar', {
          show: true,
          color: 'success',
          message: 'Your file is being processed and you will receive an email when all updates are completed.',
          timeout: 8000
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async importEmployees(context, file) {
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      try {
        const res = await HTTP.post('members/import/employees', file, { headers })
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getCurrentElectionStatistics(context, { memberId, electionId }) {
      try {
        const res = await HTTP.get(
          `members/${ memberId }/election-cycle/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getEmployeesByVoteStatus(context, { memberId, electionId, selectedJobType, selectedVoteCategory }) {
      try {
        const response = await HTTP.get(
          `members/${ memberId }/voting-statistics/${ electionId }/employees-by-vote-status`,
          {
            params: {
              role: selectedJobType,
              registered: selectedVoteCategory
            },
            headers: helper.setHeaders()
          })
        return response.data.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getOrganizationOverview(context, memberId) {
      try {
        const response = await HTTP.get(
          `members/${ memberId }/organinzation-overview`,
          { headers: helper.setHeaders() }
        )
        return response.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getDonationStatistics({ rootState, commit }) {
      try {
        const res = await HTTP.get(
          `members/${ rootState.user.profile_id.member_id }/donation-statistics`,
          { headers: helper.setHeaders() }
        )
        commit('SET_DONATION_STATISTICS', res.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getSubordinates({ commit, rootGetters }, electionId) {
      try {
        commit('SET_SUBORDINATES', [])
        const response = await HTTP.get(
          `members/${ rootGetters.user.profile_id.employee_id }/subordinates-election/${ electionId }`,
          {
            headers: helper.setHeaders()
          }
        )
        commit('SET_SUBORDINATES', response.data.data.subordinates)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getSubordinatesView(context, { electionId, selectedEmployeeId }) {
      try {
        const response = await HTTP.get(
          `members/${ selectedEmployeeId }/subordinates-election-view/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        return response.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async employeeRegistrationByJobType(context, { memberId, electionId }) {
      try {
        const res = await HTTP.get(
          `members/${ memberId }/job-type/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVotingStatistics({ rootGetters, commit }, electionId) {
      try {
        const res = await HTTP.get(
          `members/${ rootGetters.user.profile_id.member_id }/voting-statistics/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOTING_STATISTICS', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVolunteeringStatistics({ rootGetters, commit }) {
      try {
        const res = await HTTP.get(
          `members/${ rootGetters.user.profile_id.member_id }/volunteering-statistics`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOLUNTEERING_STATISTICS', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  },
  modules: {
    employeeStore
  }
}

import { HTTP } from '../../../http-common'
import helper from '../../services'

export default {
  namespaced: true,
  state: {
    reportingNumbers: {},
    redFlags: []
  },
  getters: {
    comparedReportingNumbers: (state) => [
      {
        icon: 'mdi-inbox-arrow-down',
        data: [
          {
            number: state.reportingNumbers.employees_scheduled,
            text: '% Registered employees scheduled to vote'
          },
          {
            number: state.reportingNumbers.employees_eligible,
            text: '% Eligible employees voted'
          },
          {
            number: state.reportingNumbers.employees_registered,
            text: '% Registered employees voted'
          }
        ]
      }
      // {
      //   icon: 'mdi-apple-keyboard-caps',
      //   data: [
      //     {
      //       number: state.reportingNumbers.voting_goal,
      //       text: '% Voting Goal'
      //     },
      //     {
      //       number: state.reportingNumbers.actual_voting,
      //       text: '% Actual Voting'
      //     }
      //   ]
      // }
    ]
  },
  mutations: {
    SET_REPORTING_NUMBERS: (state, data) => state.reportingNumbers = data,
    SET_RED_FLAGS: (state, flags) => state.redFlags = flags
  },
  actions: {
    async getReportingInformation({ rootState, commit }, params) {
      commit('SET_RED_FLAGS', [])
      try {
        const res = await HTTP.get(
          `get-reporting-information/${ rootState.electionStore.selectedElection.id }`,
          {
            params,
            headers: helper.setHeaders()
          }
        )
        const reportingNumbers = Object
          .fromEntries(Object.entries(res.data)
            .filter(el => el[0] !== 'red_flags'))
        const redFlags = res.data.red_flags.data
        commit('SET_REPORTING_NUMBERS', reportingNumbers)
        commit('SET_RED_FLAGS', redFlags)
        return res.data.red_flags
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}

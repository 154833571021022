<template>
  <v-snackbar
    v-model='snackbar.show'
    :color='snackbar.color'
    :timeout='snackbar.timeout || 4000'
    class='white--text'
    width='500'
    right
    top
  >
    {{ snackbar.message }}

    <template #action='{ attrs }'>
      <v-btn color='white' text v-bind='attrs' @click='close'> Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar() {
      if (this.$store.getters.snackbar.show) {
        this.time()
      }
      return this.$store.getters.snackbar
    }
  },
  data: () => ({}),
  methods: {
    time() {
      let time = this.$store.getters.snackbar.timeout
        ? this.$store.getters.snackbar.timeout
        : 4000
      setTimeout(() => {
        this.close()
      }, time)
    },
    close() {
      this.$store.dispatch('snackbar', {
        show: false,
        color: 'grey',
        message: ''
      })
    }
  }
}
</script>

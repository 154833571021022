import { HTTP } from '../../../http-common'
import helper from '../../services'

const charityStore = {
  namespaced: true,
  state: {
    charities: [],
    activeCharities: [],
    suspendedCharities: []
  },
  getters: {},
  mutations: {
    SET_CHARITIES: (state, data) => state.charities = data,
    SET_ACTIVE_CHARITIES: (state, data) => state.activeCharities = data,
    SET_SUSPENDED_CHARITIES: (state, data) => state.suspendedCharities = data
  },
  actions: {
    async createCharity(context, data) {
      try {
        const res = await HTTP.post('charity', data, {
          headers: helper.setHeaders()
        })
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getCharities({ commit }, params) {
      try {
        const res = await HTTP.get(
          'charity',
          {
            params,
            headers: helper.setHeaders()
          }
        )
        commit('SET_CHARITIES', res.data.data)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getSingleCharity(context, id) {
      try {
        const res = await HTTP.get(`charity/${ id }`, {
          headers: helper.setHeaders()
        })
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateCharity(context, data) {
      try {
        const { id, ...info } = data
        const res = await HTTP.put(`charity/${ id }`, info, {
          headers: helper.setHeaders()
        })
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async toggleSuspendCharity(context, { id, suspended }) {
      try {
        const res = await HTTP.put(`charity/suspend/${ id }`, { suspended }, {
          headers: helper.setHeaders()
        })
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getSuspendedCharities({ commit }, params) {
      try {
        const res = await HTTP.get(
          'suspended-charities',
          {
            params,
            headers: helper.setHeaders()
          }
        )
        commit('SET_SUSPENDED_CHARITIES', res.data.data)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async deleteCharity(context, charityId) {
      try {
        const res = await HTTP.delete(`charity/${ charityId }`, {
          headers: helper.setHeaders()
        })
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getActiveCharities({ commit }, params) {
      try {
        const response = await HTTP.get(
          'charities',
          {
            params,
            headers: helper.setHeaders()
          }
        )
        commit('SET_ACTIVE_CHARITIES', response.data.data)
        return response.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}
export default charityStore

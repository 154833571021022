import { HTTP } from '../../../http-common'
import helper from '../../services'
import store from '../index'


export default {
  namespaced: true,
  state: {
    companyReporting: [],
    exceptionItems: [],
    uploadedFiles: [],
    adminEmployees: [],
    donationStatistics: {},
    votingStatistics: {},
    volunteeringStatistics: {},
    members: [],
    selectedMember: {}
  },
  getters: {
    allocation: state => state.donationStatistics.allocation
  },
  mutations: {
    SET_COMPANY_REPORTING: (state, data) => state.companyReporting = data,
    SET_EXCEPTION_ITEMS: (state, data) => state.exceptionItems = data,
    SET_UPLOADED_FILES: (state, data) => state.uploadedFiles = data,
    SET_ADMIN_EMPLOYEES: (state, data) => state.adminEmployees = data,
    SET_DONATION_STATISTICS: (state, data) => state.donationStatistics = data,
    SET_VOTING_STATISTICS: (state, data) => state.votingStatistics = data,
    SET_VOLUNTEERING_STATISTICS: (state, data) => state.volunteeringStatistics = data,
    SET_MEMBERS: (state, data) => state.members = data,
    SET_SELECTED_MEMBER: (state, member) => state.selectedMember = member
  },
  actions: {
    async getCompanyReporting({ rootState, commit }, data) {
      try {
        const res = await HTTP.get(
          `business/reporting/companies/${ rootState.electionStore.selectedElection.id }?ids=${JSON.stringify(data)}`,
          { headers: helper.setHeaders() }
        )
        commit('SET_COMPANY_REPORTING', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getAllEmployees({ commit }) {
      try {
        const response = await HTTP.get(
          'business/employees',
          { headers: helper.setHeaders() }
        )
        commit('SET_ADMIN_EMPLOYEES', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async updateEmployee(context, data) {
      try {
        await HTTP.put(
          'resolve-exception', data,
          { headers: helper.setHeaders() }
        )
        await store.dispatch('snackbar', {
          show: true,
          color: 'success',
          message: 'Employee updated successfully!'
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async noMatchListed(context, id) {
      try {
        await HTTP.put(
          'resolve-exception/no-match-listed', id,
          { headers: helper.setHeaders() }
        )
        await store.commit('snackbar', {
          show: true,
          color: 'success',
          message: 'Employee has been successfully added!'
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getExceptionItems({ commit }, params) {
      try {
        commit('SET_EXCEPTION_ITEMS', [])
        const res = await HTTP.get('employee-import-exceptions', {
          params,
          headers: helper.setHeaders()
        })
        commit('SET_EXCEPTION_ITEMS', res.data.data)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getUploadedFiles({ commit }, params) {
      try {
        commit('SET_UPLOADED_FILES', [])
        const res = await HTTP.get('get-import-history', {
          params,
          headers: helper.setHeaders()
        })
        commit('SET_UPLOADED_FILES', res.data.data)
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getDonationStatistics({ commit }) {
      try {
        const res = await HTTP.get(
          'business/all/overview/donations',
          { headers: helper.setHeaders() }
        )
        commit('SET_DONATION_STATISTICS', res.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async employeeRegistrationByJobType(context, { memberId, electionId }) {
      try {
        const res = await HTTP.get(
          `business/${ memberId }/overview/job-type/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getCurrentElectionStatistics(context, { electionId, memberId }) {
      try {
        const res = await HTTP.get(
          `business/${ memberId }/overview/election-cycle/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVotingStatistics({ commit }, { memberId, electionId }) {
      try {
        const res = await HTTP.get(
          `business/${ memberId }/overview/voting-statistics/${ electionId }`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOTING_STATISTICS', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getVolunteeringStatistics({ commit }, memberId) {
      try {
        const res = await HTTP.get(
          `business/${ memberId }/overview/volunteering`,
          { headers: helper.setHeaders() }
        )
        commit('SET_VOLUNTEERING_STATISTICS', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getMembers({ commit }) {
      try {
        const res = await HTTP.get(
          'business/members-list',
          { headers: helper.setHeaders() }
        )
        commit('SET_MEMBERS', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}

import {HTTP} from '../../../http-common'
import helper from '../../services'

export default {
  namespaced: true,
  state: {
    sections: {},
    loginContent: [],
    cmsContent: [],
    selectedRoleId: null
  },
  getters: {
    getContentByRole: state => roleId => state.cmsContent
      .filter(el => el.userProfileId === roleId && el.name !== 'Cms')
      .map(el => {
        return {
          pageId: el.id,
          pageName: el.name,
          content: el.content
        }
      }),
    getGeneralSettingsContent: state => {
      return state.cmsContent
        .filter(el => el.userProfileId === null)
        .map(element => {
          return {
            pageId: element.id,
            pageName: element.name,
            content: element.content
          }
        })
    },
    loginPageContent: state => {
      let sections = {}
      state.loginContent.forEach(page => {
        page.content.forEach(el => sections[el.section_name.toLowerCase()
          .split(' ')
          .join('_')] = el.content)
      })
      return sections
    }
  },
  mutations: {
    SET_PAGES_CONTENT: (state, sections) => state.sections = sections,
    SET_CMS_CONTENT: (state, content) => state.cmsContent = content,
    SELECT_ROLE_ID: (state, id) => state.selectedRoleId = id,
    SET_LOGIN_CONTENT: (state, content) => state.loginContent = content
  },
  actions: {
    async getLoginContent({commit}) {
      try {
        const res = await HTTP.get(
          'get-login-content',
          {headers: helper.setHeaders()}
        )
        commit('SET_LOGIN_CONTENT', res.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getCMSContent({commit}) {
      try {
        const res = await HTTP.get(
          'business/get-page-details',
          {headers: helper.setHeaders()}
        )
        commit('SET_CMS_CONTENT', res.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async sendSectionData(context, data) {
      try {
        const res = await HTTP.post(
          'business/update-page-details', data,
          {headers: helper.setHeaders()}
        )
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async sendSectionDataImage(context, {data, id}) {
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      try {
        const res = await HTTP.post(
          `business/upload-page-detail-img/${id}`, data,
          {headers: headers}
        )
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}
import {HTTP} from '../../../http-common'
import helper from '../../services'

const donationStore = {
  namespaced: true,
  state: {
    donations: []
  },
  getters: {
    getFilteredDonations({donations}) {
      return donations.filter(don => {
        if (don.event_type_id === 2 && don.bank_account_status === 1) {
          const percentage = Math.round(don.total_donations * 100) / don.mount_required
          don.percentage = percentage > 100 ? 100 : percentage
          return don
        }
      })
    },
    getFundsForCharity: ({donations}) => donations.filter(don => don.event_type_id === 3)
  },
  mutations: {
    SET_DONATIONS: (state, data) => state.donations = data
  },
  actions: {
    async cancelSubscription({commit}, id) {
      try {
        const res = await HTTP.post(
          'stripe-subscription-cancel-by-user',
          {id},
          {headers: helper.setHeaders()}
        )
        commit('SET_CUSTOM_ALERT_DATA', {
          show: true,
          color: 'green',
          message: res.data.message
        })
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async getDonations({commit}) {
      try {
        const res = await HTTP.get(
          'donation-opportunities',
          {headers: helper.setHeaders()}
        )
        commit('SET_DONATIONS', res.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async donate(context, data) {
      try {
        const res = await HTTP.post(
          'stripe-charge', data,
          {headers: helper.setHeaders()}
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
        return e.response
      }
    },
    async sendSubscription(context, data) {
      try {
        const res = await HTTP.post(
          'stripe-subscription-operation', data,
          {headers: helper.setHeaders()}
        )
        return res.data
      } catch (e) {
        await helper.errorHandler(e.response)
        return e.response
      }
    },
    async sendDonationRequest(context, data) {
      try {
        await HTTP.post(
          'charity-requests', data,
          {headers: helper.setHeaders()}
        )
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}
export default donationStore
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VCurrencyField from 'v-currency-field'
import {VTextField} from 'vuetify/lib'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import VueSmoothScroll from 'vue3-smooth-scroll'
import wysiwyg from 'vue-wysiwyg' //Globally import VTextField
import VueGoogleCharts from 'vue-google-charts'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import NProgress from 'vue-nprogress'
import LottieAnimation from 'lottie-web-vue'

Vue.use(NProgress)
Vue.use(wysiwyg, {})
Vue.component('v-text-field', VTextField)
Vue.component('tags-input', VoerroTagsInput)
Vue.use(VCurrencyField, {
  locale: 'en-US',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})
Vue.use(VueGoogleCharts)
Vue.use(VueSmoothScroll)
Vue.use(LottieAnimation);
Vue.config.productionTip = false

const nprogress = new NProgress()

new Vue({
  router,
  store,
  vuetify,
  nprogress,
  render: (h) => h(App)
}).$mount('#app')

<template>
  <v-app id='inspire'>
    <nprogress-container/>
    <snack-bar/>
    <template v-if='auth'>
      <global-loader/>
      <header-component @toggleSidebar='onSidebar'/>
      <side-bar-component :drawer='drawer'/>
      <v-main class='background'>
        <impersonal/>
        <router-view/>
      </v-main>
    </template>
    <template v-if='!auth'>
      <router-view/>
    </template>
  </v-app>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import HeaderComponent from './components/HeaderComponent.vue'
import SideBarComponent from './components/SideBarComponent.vue'
import SnackBar from './components/SnackBar.vue'
import Impersonal from './components/Impersonal.vue'
import GlobalLoader from './components/GlobalLoader'

export default {
  components: { GlobalLoader, HeaderComponent, SideBarComponent, SnackBar, Impersonal, NprogressContainer },
  data() {
    return {
      drawer: true
    }
  },
  computed: {
    auth() {
      return this.$store.getters.token !== ''
    }
  },
  created() {
    if (window.innerWidth < 960) this.drawer = false
  },
  methods: {
    onSidebar(value) {
      this.drawer = value
    }
  }
}
</script>

<style lang='scss'>
.background {
  background-color: #f9f9fb;
  @media (max-width: 960px) {
    padding-top: 10px !important;
  }
}
#nprogress .spinner {
  display: none;
}
</style>

import { HTTP } from '../../../http-common'
import helper from '../../services'

const userStore = {
  namespaced: true,
  state: {
    members: []
  },
  getters: {},
  mutations: {
    SET_MEMBERS: (state, data) => state.members = data
  },
  actions: {
    async getMembers({ commit }, id) {
      try {
        const response = await HTTP.get(
          `getEntity/${ id }`,
          { headers: helper.setHeaders() }
        )
        commit('SET_MEMBERS', response.data.data)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async resendVerificationEmail(context, employeeId) {
      try {
        const res = await HTTP.get(
          `employees/resend-verification-email/${ employeeId }`,
          { headers: helper.setHeaders() }
        )
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    },
    async sendVerificationEmail(context, employeeIds) {
      try {
        const res = await HTTP.post(
          'employees/send-verification-email', employeeIds,
          { headers: helper.setHeaders() }
        )
        await helper.errorHandler(res)
      } catch (e) {
        await helper.errorHandler(e.response)
      }
    }
  }
}
export default userStore

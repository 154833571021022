<template>
  <div v-frag>
    <v-sheet
      v-if='impersonal'
      class='text-center impersonal'
      color='deep-orange lighten-4'
      elevation='0'
    >
      Guest session Logged as <b v-if="user.member_name !== ' '">{{ user.member_name}}</b><b v-else>{{ user.rol }}</b> : user <b>{{ user.name }}</b>.
      <v-btn
        class='primary--text'
        plain
        tile
        @click='backMainAccount'
      >
        Back to business admin
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user
    },
    impersonal() {
      return this.$store.getters.impersonal
    },
    userCopy() {
      return this.$store.getters.userCopy
    }
  },
  methods: {
    async backMainAccount() {
      const aux = this.$store.getters.userCopy
      await this.$store.dispatch('userCopy', {
        user: this.$store.getters.user,
        token: this.$store.getters.token
      })
      await this.$store.dispatch('user', aux.user)
      await this.$store.dispatch('token', aux.token)
      await this.$store.dispatch('impersonal', false)
      await this.$router.replace(aux.user.url[0].default_path)
      location.reload()
    }
  }
}
</script>

<style scoped>
.impersonal {
  font-size: 20px;
}
</style>

import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  addMember: async (data) => {
    const response = await HTTP.post(
      'members', data,
      { headers: helper.setHeaders() }
    )
    let formData = new FormData()
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    formData.append('file', data.file)
    await HTTP.post('members/upload/' + response.data.member.id, formData, {
      headers: headers
    })
  },
  editMember: async (data) => {
    await HTTP.put(
      'members/' + data.id, data,
      { headers: helper.setHeaders() }
    )
    let formData = new FormData()
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    formData.append('file', data.file)
    await HTTP.post('members/upload/' + data.id, formData, {
      headers: headers
    })
  },
  enableDisableMember: async ({ id }) => {
    await HTTP.put(
      'members/enable-disable',
      { id },
      {
        headers: helper.setHeaders()
      }
    )
  },
  enableDisableMembers: async ({ members }) => {
    await HTTP.put(
      'members/enable-disable-multiple',
      { members },
      {
        headers: helper.setHeaders()
      }
    )
  },
  deleteMember: async ({ id }) => {
    await HTTP.put(
      'members/delete',
      { id },
      {
        headers: helper.setHeaders()
      }
    )
  },
  deleteMembers: async ({ members }) => {
    await HTTP.put(
      'members/delete-multiple',
      { members },
      {
        headers: helper.setHeaders()
      }
    )
  },
  disableMember: async ({ id }) => {
    await HTTP.delete('members/disable/' + id,
      {
        headers: helper.setHeaders()
      })
  },
  editModules: async ({ members, modules }) => {
    await HTTP.put(
      'members/modules',
      { members, modules },
      {
        headers: helper.setHeaders()
      }
    )
  },
  getMembers: async params => {
    const response = await HTTP.get('members', {
      params,
      headers: helper.setHeaders()
    })
    return response.data
  },
  getAllMembers: async () => {
    const response = await HTTP.get('business/members', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  pendingRequests: async ({ id }) => {
    const response = await HTTP.get('members/' + id + '/pending-requests', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  approveRequests: async ({ requestIds, id }) => {
    await HTTP.put(
      'members/' + id + '/approve-requests',
      { requestIds },
      {
        headers: helper.setHeaders()
      }
    )
  },
  historicalRequests: async ({ id }) => {
    const response = await HTTP.get('members/' + id + '/historical-requests', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  rejectRequests: async ({ requestIds, id }) => {
    await HTTP.post(
      'members/' + id + '/reject-requests',
      { requestIds },
      {
        headers: helper.setHeaders()
      }
    )
  },
  getSuspendedMembers: async params => {
    const response = await HTTP.get('members/suspended', {
      params,
      headers: helper.setHeaders()
    })
    return response.data
  },
  uploadFile: async ({ id, file }) => {
    let formData = new FormData()
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    formData.append('file', file)
    const response = await HTTP.post('members/upload/' + id, formData, {
      headers: headers
    })
    return response.data
  },
  download: async () => {
    const response = await HTTP.get('downloadExcel', {
      headers: helper.setHeaders()
    })
    location.href = response.data
    return true
  },
  downloadEmployeeImportTemplateAdmin: async () => {
    const response = await HTTP.get('downloadEmployeeTemplateAdmin', {
      headers: helper.setHeaders()
    })
    location.href = response.data
    return true
  },

  downloadEmployee: async () => {
    const response = await HTTP.get('employee-exports', {
      headers: helper.setHeaders()
    })
    location.href = response.data
    return true
  },
  modules: async (memberId) => {
    const response = await HTTP.get('members/' + memberId + '/modules', {
      headers: helper.setHeaders()
    })
    return response.data
  },
  elections: async (memberId) => {
    const response = await HTTP.get('members/' + memberId + '/elections', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  votingStatistics: async ({ memberId, electionId }) => {
    const response = await HTTP.get(
      'members/' + memberId + '/voting-statistics/' + electionId,
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  getEmployeesByVoteStatus: async ({ memberId, electionId, selectedJobType, selectedVoteCategory }) => {
    const response = await HTTP.get(
      'members/' + memberId + '/voting-statistics/' + electionId + '/employees-by-vote-status/',
      {
        params: {
          'role': selectedJobType,
          'registered': selectedVoteCategory
        },
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  volunteeringStatistics: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/volunteering-statistics',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  donationStatistics: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/donation-statistics',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  organinzationOverview: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/organinzation-overview',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  charityStatisticsHeaders: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/charity-statistics-headers',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  charitiesDetail: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/charity-statistics-detail',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  votingStatisticsCards: async ({ memberId, electionId }) => {
    const response = await HTTP.get(
      'members/' + memberId + '/voting-statistics/' + electionId + '/cards',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  votingStatisticsElectionData: async ({ memberId, electionId }) => {
    const response = await HTTP.get(
      'members/' +
      memberId +
      '/voting-statistics/' +
      electionId +
      '/election-data',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  scheduledCommunicationPlan: async (memberId) => {
    const response = await HTTP.get(
      'members/' + memberId + '/scheduled-communication-plan',
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getSubordinates: async ({ electionId, employeeId }) => {
    const response = await HTTP.get(
      'members/' + employeeId + '/subordinates-election/' + electionId,
      {
        headers: helper.setHeaders()
      }
    )
    return response.data.data
  },
  getSubordinatesView: async ({ electionId, employeeId }) => {
    const response = await HTTP.get(
      'members/' + employeeId + '/subordinates-election-view/' + electionId,
      {
        headers: helper.setHeaders()
      }
    )
    return response.data
  },
  importEmployees: async (file) => {
    let formData = new FormData()
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    formData.append('file', file)
    const response = await HTTP.post('members/import/employees', formData, {
      headers: headers
    })
    return response.data
  },
  updateEmployees: async (file) => {
    let formData = new FormData()
    formData.append('file', file)
    let headers = helper.setHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    const response = await HTTP.post('employees-update-import', formData, {
      headers: headers
    })
    return response.data
  },
  getMemberYear: async (memberId) => {
    const res = await HTTP.get(`member-year/${ memberId }`, {
      headers: helper.setHeaders()
    })
    return res.data
  },
  createMemberYear: async (data) => {
    try {
      const res = await HTTP.post('member-year/create', data, {
        headers: helper.setHeaders()
      })
      return res.data
    } catch (e) {
      return e.response
    }
  },
  updateMemberYear: async (data) => {
    try {
      const res = await HTTP.post('member-year/update', data, {
        headers: helper.setHeaders()
      })
      return res.data
    } catch (e) {
      return e.response
    }
  },
  setCharity: async (data) => {
    await HTTP.put(
      '/members/charity-restriction',
      {
        id: data.id,
        restricted: data.restricted
      },
      {
        headers: helper.setHeaders()
      }
    )
  }
}

import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  login: async({email, password}) => {
    try {
      return await HTTP.post(
        'login',
        {
          email,
          password
        },
        {
          headers: helper.setHeaders()
        }
      )
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  checkAuth: async() => {
    try {
      const response = await HTTP.get('check-auth', {
        headers: helper.setHeaders()
      })
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  logout: async() => {
    try {
      const response = await HTTP.post(
        'logout',
        {},
        {
          headers: helper.setHeaders()
        }
      )
      return response.data.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  newPassword: async({password, confirm, agreements, id}) => {
    try {
      const response = await HTTP.post(
        'registerPassword',
        {
          password,
          password_confirmation: confirm,
          agreements: agreements,
          id: id
        },
        {
          headers: helper.setHeaders()
        }
      )
      return response.data
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  },
  resetPassword: async({email}) => {
    try {
      return await HTTP.post(
        'resetPassword',
        {
          email
        },
        {
          headers: helper.setHeaders()
        }
      )
    } catch (e) {
      helper.errorHandler(e.response)
      return e.response
    }
  }
}

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import frag from 'vue-frag'
import VueMask from 'v-mask'
import VueRx from "vue-rx"
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'
import 'vuejs-clipper/dist/vuejs-clipper.css'

Vue.use(VueRx);
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true,
    clipperFixed: true
  }
})
Vue.use(VueMask)
Vue.directive('frag', frag)

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#5b8dd7',
        secondary: '#9baac1',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
})

import {HTTP} from '../../../http-common'
import helper from '../index'
import store from '../../store'
import getSections from './sectionsContent'


export default {
  getOperations: async() => {
    const response = await HTTP.get('getOperations', {
      headers: helper.setHeaders()
    })
    let sections = {}
    sections = getSections(response.data.data)
    store.commit('cmsStore/SET_PAGES_CONTENT', sections)
    return response.data.data
  }
}

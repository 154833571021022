import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard')
const Members = () => import(/* webpackChunkName: "members" */ '../views/member/Members')
const MemberCharities = () => import(/* webpackChunkName: "member-charities" */ '../views/member/Charities')

// import Overview from "../views/Overview";
const Login = () => import(/* webpackChunkName: "login" */ '../views/home/Login')
const Users = () => import(/* webpackChunkName: "users" */ '../views/user/Users')
const Charity = () => import(/* webpackChunkName: "charity" */ '../views/charity/charity')
const CharityEvents = () => import(/* webpackChunkName: "charity-events" */ '../views/charity/CharityEvents')
const Employee = () => import(/* webpackChunkName: "employee" */ '../views/employee/Employee')
const NewPassword = () => import(/* webpackChunkName: "new-password" */ '../views/home/NewPassword')
const ConfirmationAccount = () => import(/* webpackChunkName: "confirmation-account" */ '../views/home/ConfirmationAccount')
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */ '../views/home/ResetPassword')
const FundraisingNeeds = () => import(/* webpackChunkName: "fundraising-needs" */ '../views/charity/FundraisingNeeds')
const CharityEventDetail = () => import(/* webpackChunkName: "charity-event-detail" */ '../views/charity/CharityEventDetail')
const DonationsSummary = () => import(/* webpackChunkName: "donations-summary" */ '../views/charity/DonationsSummary')
const Opportunities = () => import(/* webpackChunkName: "opportunities" */ '../views/opportunities/Opportunities')
const CharityProfile = () => import(/* webpackChunkName: "charity-profile" */ '../views/charity/Profile')
const OpportunityDetails = () => import(/* webpackChunkName: "opportunity-details" */ '../views/opportunities/Details')
const EmployeeProfile = () => import(/* webpackChunkName: "employee-profile" */ '../views/employee/Profile')
const Donations = () => import(/* webpackChunkName: "donations" */ '../views/employee/Donations')
const CharityDonations = () => import(/* webpackChunkName: "charity-donations" */ '../views/employee/CharityDonations')
const Communication = () => import(/* webpackChunkName: "communication" */ '../views/communication/Communication')
const ReportingMaps = () => import(/* webpackChunkName: "reporting-maps" */ '../views/member/ReportingMaps')
const DaysOffRequests = () => import(/* webpackChunkName: "days-off-requests" */ '../views/employee/DaysoffRequests')
const Campaign = () => import(/* webpackChunkName: "campaign" */ '../views/campaing/Campaing')
const Approvals = () => import(/* webpackChunkName: "approvals" */ '../views/approvals/Approvals')
const MemberOverview = () => import(/* webpackChunkName: "member-overview" */ '../views/member/Overview')
const MemberVoting = () => import(/* webpackChunkName: "member-voting" */ '../views/member/Voting')
const Error410 = () => import(/* webpackChunkName: "error-410" */ '../views/error/error410')
const VotersGuide = () => import(/* webpackChunkName: "voters-guide" */ '../views/admin/VotersGuide')
const BusinessEmployeesPage = () => import(/* webpackChunkName: "business-employees-page" */ '../views/employee/BusinessEmployeesPage.vue')
const VotersGuideEmployee = () => import(/* webpackChunkName: "voters-guide-employee" */ '../views/employee/VotersGuide')
const DialogCampaign = () => import(/* webpackChunkName: "dialog-campaign" */ '../views/campaing/components/DialogCampaign')
const ImportVoting = () => import(/* webpackChunkName: "import-voting" */ '../views/importVoting/ImportVoting')
const AdminOverview = () => import(/* webpackChunkName: "admin-overview" */ '../views/admin/Overview')
const MemberDonations = () => import(/* webpackChunkName: "member-donations" */ '../views/member/MemberDonations')
const PartnerCharities = () => import(/* webpackChunkName: "partner-charities" */ '../views/employee/PartnerCharities')
const EmployeeOverview = () => import(/* webpackChunkName: "employee-overview" */ '../views/employee/EmployeeOverview')
const MyVoting = () => import(/* webpackChunkName: "my-voting" */ '../views/employee/MyVoting')
const ManageMyTeam = () => import(/* webpackChunkName: "manage-my-team" */ '../views/employee/ManageMyTeam')
const FAQs = () => import(/* webpackChunkName: "faqs" */ '../views/employee/FAQs')
const CharityInvolvementVolunteering = () => import(/* webpackChunkName: "charity-involvement-volunteering" */ '../views/employee/CharityInvolvementVolunteering')
const CharityInvolvementDonations = () => import(/* webpackChunkName: "charity-involvement-donations" */ '../views/employee/CharityInvolvementDonations')
const ProfileEmployee = () => import(/* webpackChunkName: "profile-employee" */ '../views/employee/EmployeeProfile')
const RegistrationPage = () => import(/* webpackChunkName: "registration-page" */ '../views/member/RegistrationPage')
const GeneralSettingsPage = () => import(/* webpackChunkName: "general-settings-page" */ '../pages/GeneralSettingsPage')
const EditPage = () => import(/* webpackChunkName: "edit-page" */ '../pages/EditPage')
const AdminPage = () => import(/* webpackChunkName: "admin-page" */ '../pages/AdminPage')
const CompanyReportingPage = () => import(/* webpackChunkName: "company-reporting-page" */ '../pages/CompanyReportingPage')
const ReportingPage = () => import(/* webpackChunkName: "reporting-page" */ '../pages/ReportingPage')
const EmployeeCharities = () => import(/* webpackChunkName: "EmployeeCharities" */ '../views/employee/EmployeeCharities')

Vue.use(VueRouter)

const ifRouteValid = (routeTo, routeFrom, next) => {
  const validRoutes = ['business-admin', 'member-admin', 'charity-admin', 'member-hierarchy', 'individual-employee']
  const isRouteValid = validRoutes.some(route => route === routeTo.params.pageName)
  isRouteValid ? next() : next({name: 'AdminPage'})
}

const ifLoggedAsAdmin = (to, from, next) => {
  (store.state.user.id_rol === 1) ? next() : next({name: 'Home'})
}

const ifNotAuthenticated = (to, from, next) => {
  if (store.getters.token === '') {
    next()
  } else {
    next({name: 'Home'})
  }
}

const ifAuthenticated = (to, from, next) => {
  if (to.name === 'VotersGuideEmployee' && store.getters.token === '') {
    store.dispatch('voted_url', 'VotersGuideEmployee')
  }
  if (store.getters.token !== '') {
    next()
  } else {
    store.commit('saveVoterGuideUserParams', {...to.params})
    next({name: 'Login'})
  }
}

const accessToReportingPage = (to, from, next) => {
  store.state.user.id_rol === 2 || store.state.user.id_rol === 5
    ? next()
    : next({name: 'Home'})
}

const routes = [
  {
    path: '/company-reporting',
    name: 'CompanyReporting',
    component: CompanyReportingPage,
    beforeEnter: ifLoggedAsAdmin
  },
  {
    path: '/reporting',
    name: 'ReportingPage',
    component: ReportingPage,
    beforeEnter: accessToReportingPage
  },
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    beforeEnter: ifLoggedAsAdmin
  },
  {
    path: '/admin/general-settings',
    name: 'GeneralSettingsPage',
    component: GeneralSettingsPage,
    beforeEnter: ifLoggedAsAdmin
  },
  {
    path: '/admin/:pageName',
    name: 'EditPage',
    component: EditPage,
    beforeEnter: ifLoggedAsAdmin && ifRouteValid
  },
  {
    path: '/admin/*',
    redirect: {name: 'EditPage'}
  },
  {
    path: '/registration',
    name: 'RegistrationPage',
    component: RegistrationPage,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/employee-overview',
    name: 'EmployeeOverview',
    component: EmployeeOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-my-voting',
    name: 'MyVoting',
    component: MyVoting,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-charities',
    name: 'EmployeeCharities',
    component: EmployeeCharities,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-volunteering',
    name: 'CharityInvolvementVolunteering',
    component: CharityInvolvementVolunteering,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-donations',
    name: 'CharityInvolvementDonations',
    component: CharityInvolvementDonations,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-manage-my-team',
    name: 'ManageMyTeam',
    component: ManageMyTeam,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-profile',
    name: 'ProfileEmployee',
    component: ProfileEmployee,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee-faq',
    name: 'FAQs',
    component: FAQs,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/business/overview',
    name: 'MemberBusiness',
    component: AdminOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/business/employees',
    name: 'Employees',
    component: BusinessEmployeesPage,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/business/voters-guide',
    name: 'VotersGuide',
    component: VotersGuide,
    beforeEnter: ifAuthenticated
  },
  // {
  //   path: "/overview",
  //   name: "Overview",
  //   component: Overview,
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: '/members',
    name: 'Member',
    component: Members,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/member/reporting-map',
    name: 'ReportingMaps',
    component: ReportingMaps,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/member/overview',
    name: 'Overview',
    component: MemberOverview,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/member/charities',
    name: 'MemberCharities',
    component: MemberCharities,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/member/voting',
    name: 'Voting',
    component: MemberVoting,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/member/charity-donations/:id',
    name: 'MemberDonations',
    component: MemberDonations,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/charities',
    name: 'Charity',
    component: Charity,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/charity-events/:id',
    name: 'CharityEvents',
    component: CharityEvents,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/error410',
    name: 'Error410',
    component: Error410
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employees',
    name: 'Employee',
    component: Employee,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/partner-charities',
    name: 'PartnerCharities',
    component: PartnerCharities,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/donations',
    name: 'Donations',
    component: Donations,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/charity-donations',
    name: 'CharityDonations',
    component: CharityDonations,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/voters-guide/:employeeId/:electionId',
    name: 'VotersGuideEmployee',
    component: VotersGuideEmployee,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/new-password/:id',
    name: 'NewPassword',
    component: NewPassword
    // beforeEnter: ifAuthenticated,
  },
  {
    path: '/confirmation-email',
    name: 'ConfirmationAccount',
    component: ConfirmationAccount,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/fundraising-needs',
    name: 'FundraisingNeeds',
    component: FundraisingNeeds
  },
  {
    path: '/charity-event-detail/:id',
    name: 'CharityEventDetail',
    component: CharityEventDetail
  },
  {
    path: '/donations-summary',
    name: 'DonationsSummary',
    component: DonationsSummary
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: Opportunities,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/opportunity-detail/:id',
    name: 'Details',
    component: OpportunityDetails,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/charities/profile',
    name: 'CharityProfile',
    component: CharityProfile,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/profile',
    name: 'EmployeeProfile',
    component: EmployeeProfile,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/profile/:id',
    name: 'EmployeeProfileById',
    component: EmployeeProfile,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/employee/days-off-requests',
    name: 'EmployeeDaysOffRequests',
    component: DaysOffRequests,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/communication',
    name: 'Communication',
    component: Communication,
    beforeEnter: ifAuthenticated
  },

  {
    path: '/campaign',
    name: 'Campaign',
    component: Campaign,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/campaign-store',
    name: 'DialogCampaign',
    component: DialogCampaign,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/campaign-update/:id',
    name: 'DialogCampaignUpdate',
    component: DialogCampaign,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/approvals',
    name: 'Approvals',
    component: Approvals,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/import-voting/:campaing',
    name: 'ImportVoting',
    component: ImportVoting,
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import helper from '../../services'
import {HTTP} from '../../../http-common'

export default {
  namespaced: true,
  state: {
    uploadFileData: {
      isDialogOpen: false,
      title: '',
      subtitle: '',
      fileType: '',
      downloadEmployeeBtn: false
    }
  },
  getters: {},
  mutations: {
    TOGGLE_UPLOAD_FILE_DIALOG: (state, data) => state.uploadFileData = data
  },
  actions: {
    async sendFile({commit, dispatch}, file) {
      let formData = new FormData()
      formData.append('file', file)
      let headers = helper.setHeaders()
      headers['Content-Type'] = 'multipart/form-data'
      try {
        commit('SET_GLOBAL_LOADER_DATA', {
          isOpen: true,
          color: 'primary',
          text: 'File is uploading...',
          progress: 0
        }, {root: true})
        const res = await HTTP.post(
          'add-state-registration-data', formData,
          {
            headers: headers,
            onUploadProgress: e => {
              commit('SET_GLOBAL_LOADER_DATA', {
                isOpen: true,
                color: 'primary',
                text: 'File is uploading...',
                progress: Math.round(e.loaded * 100 / e.total)
              }, {root: true})
            }
          }
        )
        commit('SET_GLOBAL_LOADER_DATA', {
          isOpen: false,
          color: '',
          text: '',
          progress: 0
        }, {root: true})
        dispatch('snackbar', {
          show: true,
          color: 'success',
          message: 'File uploaded successfully!'
        }, {root: true})
        return res.data
      } catch (e) {
        commit('SET_GLOBAL_LOADER_DATA', {
          isOpen: false,
          color: '',
          text: '',
          progress: 0
        }, {root: true})
        await helper.errorHandler(e.response)
      }
    }
  }
}

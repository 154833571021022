import {HTTP} from '../../../http-common'
import helper from '../../services'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async clearDivision(context, data) {
      await HTTP
        .put(
          'voters-guide/clear', data,
          {headers: helper.setHeaders()}
        )
        .catch(async(e) => await helper.errorHandler(e.response))
    },
    async deleteLegislation(context, id) {
      await HTTP
        .delete(
          `voter-guide-legislation/${id}`,
          {headers: helper.setHeaders()}
        )
        .catch(async(e) => await helper.errorHandler(e.response))
    }
  }
}
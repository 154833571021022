const getSections = (pages) => {
  let sections = {}
  pages.forEach(page => {
    let section = getSectionsContent(page)
    section.forEach(el => {
      for (const key in el) {
        let sectionKey = `${key}_${page.name.toLowerCase().replaceAll(' ', '_')}`
          sections[sectionKey] = el[key]
          sections[key] = el[key]
      }
    })
  })
  return sections
}

const getSectionsContent = (page) => {
  return page.content.map(section => section)
}

export default getSections